<template>
  <div id="course-create-details-tab">
    <div class="vx-col w-full">
        <div class="vx-row ">
          <div class="vx-col sm:w-1/2 w-full mb-2 ">
              <vs-input class="w-full" label-placeholder="Module Name" v-model="dataLocal.module_name" @change="moduleUpdateData"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2 ">
            <v-select placeholder="Module Status" class="w-full mt-5" :options="status_options" v-model="dataLocal.module_description" @input="moduleUpdateData"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full mt-2">
            <vs-textarea  label="Module Description" counter="400" :counter-danger.sync="module_description_counter" height="100px" v-model="dataLocal.module_status" @change="moduleUpdateData"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button color="success" type="filled" class="mb-2 mr-2"
                       @click="submitData">Create Module
            </vs-button>
            <vs-button color="warning" type="border" class="mb-2 ml-2"
                       @click="resetData">Reset
            </vs-button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data () {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      module_description_counter: null,

      status_options: [
        'active',
        'hidden',
        'disabled'
      ]
    }
  },
  components: {
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    moduleUpdateData () {
      this.$emit('update_module_data', this.dataLocal)
    },
    submitData () {
      this.$emit('insert_module_data', this.dataLocal)
    },
    resetData () {
      this.dataLocal =  {module_name: undefined, module_description: undefined, module_status: undefined}
      this.moduleUpdateData()
    }
  }
}
</script>

<style scoped>

</style>
