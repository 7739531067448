<!-- ================:=========================================================================
  File Name: EditCourse.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="course-new">

    <vx-card>

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <module-details-tab :data="simulation_data" v-on:update_simulation_data="moduleDataUpdate" v-on:insert_simulation_data="create_simulation"/>
            </div>
          </vs-tab>
         - <vs-tab label="Modules" icon-pack="feather" icon="icon-package">
            <div class="tab-text">
              <courses-modules-tab :data="simulation_data" v-on:update_courseData="courseDataUpdate"/>
            </div>
          </vs-tab>
          <vs-tab label="Finalize" icon-pack="feather" icon="icon-share-2">
            <div class="tab-text">

            </div>
          </vs-tab> -
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import SimulationDetailsTab from './SimulationDetailsTab.vue'

export default {
  components: {
    SimulationDetailsTab
  },
  data () {
    return {
      simulation_data: {
        module_name: undefined,
        module_description: undefined,
        module_status: undefined
      },

      activeTab: 0
    }
  },
  watch: {
    activeTab () {
    //tab change
    }
  },
  methods: {
    moduleDataUpdate (data) {
      this.simulation_data = data
    },
    create_simulation () {

      const pl = {
        name: this.simulation_data.module_name,
        description: this.simulation_data.module_description,
        status: this.simulation_data.module_status === undefined ? 'active' : this.courseData.module_status
      }

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create module',
          text: 'Please provide a Module Name',
          color: 'danger',
          position: 'top-right'
        })
      }

      this.$vs.loading()

      this.$http.post('modules/create', pl)
        .then(response => {

          this.$vs.notify({
            title: 'Created module successfully',
            text: `New module ${pl.name} has been created.`,
            color: 'success',
            position: 'top-right'
          })

          if (response.status === 200) {
            const data = response.data.data
            this.$router.push(`/a/modules/${data.id}`)
          }

        })
        .catch(exception => {
          this.$vs.loading.close()

          let error = 'An unknown error occurred while creating this module'
          if (exception.response) {
            error = exception.response.data.error.description
          }

          return this.$vs.notify({
            title: 'Failed to create module',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }

  }
}

</script>
